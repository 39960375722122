import ReactLogo from "../images/reactLogo.png"
import TailwindLogo from "../images/tailwindLogo.png"
import CSharpLogo from "../images/csharpLogo.png"
import ToolsLogo from "../images/toolsLogo.png"
import FlagsLogo from "../images/flagsLogo.png"
import EtcLogo from "../images/etcLogo.png"

function Skills () {
	return (
		<div className="w-full h-auto flex flex-col flex-wrap text-orange-100 items-center">
			<div className="lg:w-[40%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">ReactJS : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						- Redux Toolkit <br />
						- Typescript <br />
					</p>
				</div>
				<img src={ReactLogo} alt="React voxel logo" className="lg:size-[8vw] size-[25vw] transition-transform duration-300 hover:scale-110"/>
			</div>
			<div className="lg:w-[40%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<img src={TailwindLogo} alt="Tailwind logo" className="lg:size-[7vw] size-[23vw] transition-transform duration-300 hover:scale-110"/>
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-right underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">FrameworkCSS : </h1>
					<p className="font-light text-right lg:text-[1.5vw] text-[4vw]">
						- Tailwind <br />
					</p>
				</div>
			</div>
			<div className="lg:w-[40%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">C# : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						- Unity (2019 - 2022) <br/>
						- .NetCore 3.1 <br/>
					</p>
				</div>
				<img src={CSharpLogo} alt="C# voxel logo" className="lg:size-[7vw] size-[23vw] transition-transform duration-300 hover:scale-110"/>
			</div>
			<div className="lg:w-[40%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<img src={ToolsLogo} className="lg:size-[7vw] size-[23vw] transition-transform duration-300 hover:scale-110" />
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-right underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">Outils : </h1>
					<p className="font-light text-right lg:text-[1.5vw] text-[4vw]">
						- Git <br />
						- Git Desktop <br />
					</p>
				</div>
			</div>
			<div className="lg:w-[40%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">Langues : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						- Français (Langue maternelle) <br />
						- Anglais (Bilingue) <br />
					</p>
				</div>
				<img src={FlagsLogo} alt="voxel wrench" className="lg:size-[8vw] size-[23vw] transition-transform duration-300 hover:scale-110"/>
			</div>
			<div className="lg:w-[40%] w-[95%] h-fit flex flex-row items-center justify-between">
				<img src={EtcLogo} alt="three dots voxel" className="lg:size-[8vw] size-[24vw] transition-transform duration-300 hover:scale-110" />
				<div className="w-fit mt-[5%] mb-[5%]">
					<h1 className="text-right underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">etc... : </h1>
					<p className="font-light text-right lg:text-[1.5vw] text-[4vw]">
						- Autodidacte <br />
						- Travail d'équipe <br />
						- Pair Programming
					</p>
				</div>
			</div>
		</div>
	);
}

export default Skills;