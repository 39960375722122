import Logo42 from '../images/42_Logo.svg.png';
import UdemyLogo from "../images/udemyLogo.png"

function Formation({highLight}) {
	return (
		<div className="w-full h-fit flex flex-column flex-wrap">
			<div className="lg:border-b-[4px]
								w-full h-fit text-orange-100 mt-[3%] mb-[3%] border-b-2 border-orange-100">
				<img className="lg:size-1/3 lg:mt-[0%]
									float-right size-2/4 mt-[10%] pl-[10%] transition-transform duration-300 hover:scale-110" src={UdemyLogo} alt="Udemy logo" />
				<h1 className="lg:text-[1.7vw] lg:mt-[0%]
									text-center underline underline-offset-8 font-bold mt-[5%] text-[5vw]">Modern React with Redux - Udemy</h1>
				<p className="lg:text-[1.7vw] lg:mb-[5%]
									text-center font-light mb-[2%] text-[5vw] mb-[10%]">2021 - 75h</p>
				<p className="lg:text-[1.5vw] lg:mb-[6%]
									font-light text-left text-[4vw] mb-[10%]">
					- Apprentissage de <span className={highLight}>ReactJS</span> à travers des exercices et des petits projets tel que des components réutilisables, un PDA, une archive de livres lus... <br />
					<br/>
					- Utilisation de <span className={highLight}>Tailwind</span> pour styliser tous ces projets <br />
					<br/>
					- Apprentissage de <span className={highLight}>Redux Toolkit</span> <br />
					<br/>
					- Apprentissage de <span className={highLight}>Typescript</span>
					<br />
				</p>
			</div>
			<div className="w-full h-fit text-orange-100 mt-[3%] mb-[3%]">
				<img className="lg:size-[17vw] lg:mt-[0%]
									float-right size-[25vw] mr-[5%] mt-[5%] transition-transform duration-300 hover:scale-110" src={Logo42} alt="42 logo" />
				<h1 className="lg:text-[1.7vw] lg:mt-[0%]
									text-center underline underline-offset-8 font-bold mt-[5%] text-[5vw]">42 Born 2 Code</h1>
				<p className="lg:text-[1.7vw] lg:mb-[5%]
									text-center font-light mb-[2%] text-[5vw] mb-[10%]">2016 - 2019</p>
				<h2 className="lg:text-[1.5vw] lg:mb-[1%]
									text-center font-bold underline underline-offset-8 text-[4vw] mb-[5%]">Projets : </h2>
				<p className="lg:text-[1.5vw]
									font-light text-left text-[4vw]">
					- <span className={highLight}>Wolf3D</span>, une recréation d'un moteur de <span className={highLight}>raycasting</span> à la Wolfenstein 3D/Doom <span className={highLight}>(C)</span> <br />
					<br />
					- <span className={highLight}>Fractol</span>, un générateur de <span className={highLight}>Fractal</span> avec possiblité de zoomer à l'infini <span className={highLight}>(C)</span> <br />
					<br />
					- <span className={highLight}>RT</span>, le gros projet, qui a consisté à recreer un moteur de <span className={highLight}>Raytracing</span>, un moteur simulant la lumière, photon par photon, pixel par pixel, qui intétagissent avec des formes géométriques primitives (Cube, Cylindre, Sphère etc) <span className={highLight}>(C)</span> <br />
					<br />
					J'ai aussi pu effectuer un <span className={highLight}>stage</span> de <span className={highLight}>6 mois</span> durant mon temps dans l'école, ainsi que la piscine <span className={highLight}>Unity</span> qui m'a permis d'apprendre le moteur durant 1 mois intense d'exercices <br />
					<br />
					42 m'a appris le <span className={highLight}>travail d'équipe</span>, la <span className={highLight}>débrouillardise</span>, et la <span className={highLight}>programmation</span> au passage aussi
					<br />
				</p>
			</div>
		</div>
	);
}

export default Formation;