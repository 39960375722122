function LegalMentions () {
	return (
		<div className="w-full h-fit min-h-screen flex flex-col lg:text-[1.2vw] text-[4vw] mt-[5%] text-orange-100">
			<p className="font-bold">POLITIQUE D'UTILISATION DES COOKIES </p>
			<p className="ml-[3%]">
				Ce site n'utilise pas de cookies. <br />
			</p>
			<p className="font-bold">INFORMATIONS RELATIVES A LA PROTECTION DES DONNEES
				PERSONNELLES</p>
			<p className="ml-[3%]">
				Quiconque peut visiter le site https:/rowose.com sans avoir à décliner son identité ou fournir des informations personnelles. <br />
			</p>
			<p className="font-bold">DROIT APPLICABLE </p>
			<p className="ml-[3%]">
				Les présentes mentions légales sont soumises au droit français.  <br />
			</p>
			<p className="font-bold">INFORMATIONS RELATIVES A L'HEBERGEUR DU SITE</p>
			<p className="ml-[3%]">
				Site hébergé par OVH SAS, 2 rue Kellermann,59100 Roubaix, France <br />
				Numéro de téléphone : 1007
			</p>
		</div>
	)
}

export default LegalMentions;