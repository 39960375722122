import HeadDisplay from "./HeadDisplay";
import { BsChevronCompactDown } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaItchIo } from "react-icons/fa";
import { Canvas } from "@react-three/fiber";
import Typewriter from "react-ts-typewriter"

function Header({path}) {
	return (
		<div className="w-screen h-screen overflow-hidden bg-gradient-to-b from-blue-950 to-slate-950 lg:snap-center">
			<div className="w-[95%] h-[100%] ml-[2%] mt-[1%] flex align-center items-center text-orange-100">
				<div className="lg:w-[35%] lg:mt-[5%] lg:relative lg:top-[0%] lg:left-[0%] lg:opacity-100
											absolute top-[30%] left-[0%] w-[100%] h-[70%] z-0 transition-transform duration-300 hover:scale-110">
					<Canvas>
						<HeadDisplay path={path} position={[0, -0.4, 0.5]} scale={0.25} rotation={[-45.3, -0.10, 0.4]} rotationSpeed={0}></HeadDisplay>
					</Canvas>
				</div>
				<div className="lg:w-[60%] lg:h-[50%]
											w-[100%] h-[100%] z-10">
					<h1 className="lg:text-[10vw] lg:leading-[9vw]
												w-[20%] font-bold text-left text-[16vw] text-wrap leading-[14vw]">
						<Typewriter text="Rose Gaillard" />
					</h1>
					<h3 className="lg:text-[2vw]
											text-[4vw] text-left font-bold">
						<Typewriter text="Développeuse, créatrice" random={100} />
					</h3>
				</div>
			</div>
			<BsChevronCompactDown className="lg:bottom-[2%] lg:rotate-0
														text-[6vw] text-orange-100 sticky bottom-[8%] left-[50%] rotate-180"/>
			<a href="https://www.linkedin.com/in/rose-gaillard-4a3202162/">
				<FaLinkedin className="lg:text-[2vw] lg:left-[97.5%] lg:bottom-[95%]
												text-[8vw] text-orange-100 sticky bottom-[94.5%] left-[90%] z-20 transition-transform duration-300 hover:scale-110" />
			</a>
			<a href="https://rowose.itch.io">
				<FaItchIo className="lg:text-[2vw] lg:bottom-[95%] lg:left-[95.2%]
												text-[8vw] text-orange-100 sticky bottom-[94.5%] left-[81%] z-20 transition-transform duration-300 hover:scale-110"/>
			</a>
		</div>
	);
}

export default Header;