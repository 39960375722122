import ReactLogo from "../images/reactLogo.png"
import TLITBRoom from "../images/TLITBRoom.png"
import GatewatcherLogo from "../images/gatewatcherLogo.png"

function Experiences ({highLight}) {
	return (
		<div className="w-full h-fit flex flex-column flex-wrap">
			<div className="lg:border-b-[4px]
								w-full h-fit text-orange-100 mt-[3%] mb-[3%] border-b-2 border-orange-100">
				<img className="lg:size-1/3 lg:mt-[2%] 
									float-right size-1/2 mt-[7%] pl-[4%] transition-transform duration-300 hover:scale-110" src={ReactLogo} alt="Voxel React Logo" />
				<h1 className="lg:text-[1.7vw] lg:mt-[0%]
									text-center underline underline-offset-8 font-bold mt-[5%] text-[5vw]">Développeuse Web <br /> Freelance</h1>
				<p className="lg:text-[1.7vw] lg:mb-[3%]
									text-center font-light mb-[2%] text-[4vw] mb-[12%]">2021 - Actuel</p>
				<h2 className="lg:text-[1.5vw] lg:mb-[1%]
									text-center font-bold underline underline-offset-8 text-[4vw] mb-[5%]">Projets : </h2>
				<p className="lg:mb-[6%]
								font-light text-left lg:text-[1.5vw] text-[4vw] mb-[10%]">
					- Création de sites portfolio pour divers tatoueurs utilisant <span className={highLight}>ReactJS, TailWind, Typescript</span> en collaboration avec mes clients afin de rendre leurs visions réelles et de leurs fournir une plateforme où ils peuvent mettre en avant leurs travaux et permettant à leurs clients de communiquer avec eux. (Prise de rendez-vous, réservation de flashs, discutter de projets persos) <br />
					<br />
					- Création de divers projets personnels afin de travailler mes skills en développement web.
				</p>
			</div>
			<div className="lg:border-b-[4px]
								w-full h-fit text-orange-100 mt-[3%] mb-[3%] border-b-2 border-orange-100">
				<img className="lg:size-1/3 lg:mt-[2%] 
									float-right size-1/2 mt-[7%] pl-[4%] transition-transform duration-300 hover:scale-110" src={TLITBRoom} alt="3D render of immersive room from TLITB" />
				<h1 className="lg:text-[1.7vw] lg:mt-[0%]
									text-center underline underline-offset-8 font-bold mt-[5%] text-[5vw]">Développeuse C# <br/> The Lab In The Bag</h1>
				<p className="lg:text-[1.7vw] lg:mb-[3%]
									text-center font-light mb-[2%] text-[4vw] mb-[12%]">Juin 2020 - Avril 2023</p>
				<h2 className="lg:text-[1.5vw] lg:mb-[1%]
									text-center font-bold underline underline-offset-8 text-[4vw] mb-[5%]">Projets : </h2>
				<p className="lg:mb-[6%]
								font-light text-left lg:text-[1.5vw] text-[4vw] mb-[10%]">
					- Création d'une application pour Microsoft Surface sur <span className={highLight}>Unity</span>, commencant par le design de <span className={highLight}>l'UI/UX</span> jusqu'à la création de la V1 et l'ajout de feature au fil du temps et à la demande des clients <br />
					<br />
					- Création d'un package <span className={highLight}>Unity</span> permettant d'instancier des objets provenant <span className={highLight}>d'asset bundles</span> au runtime dynamiquement <br />
					<br />
					- Création d'un package <span className={highLight}>Unity</span> permettant de lire des <span className={highLight}>vidéos plate</span> ou <span className={highLight}>360</span> au runtime dynamiquement, comme un écran flottant dans un environnemment 3D <br />
					<br />
					- Ajout de feature sur une application console <span className={highLight}>.Net Core</span> permettant la communication entre l'application tablette <span className={highLight}>Unity</span> et les différents exe <span className={highLight}>Unity</span> <br />
					<br />
					- Intégration <span className={highLight}>d'environnements 3D</span> dans la salle afin de créer des scénarios de mise en scène pour les clients et leurs études marketing
				</p>
			</div>
			<div className="w-full h-fit text-orange-100 mt-[2%] mb-[3%]">
				<img className="lg:size-[12vw] lg:mr-[10%] lg:mt-[0%]
									float-right size-[25vw] ml-[10%] mt-[6%] mb-[2%] transition-transform duration-300 hover:scale-110" src={GatewatcherLogo} alt="Logo Gatewatcher" />
				<h1 className="lg:text-[1.7vw] lg:mt-[0%]
									text-center underline underline-offset-8 font-bold mt-[5%] text-[5vw]">Stagiaire Développeuse <br/> Gatewatcher</h1>
				<p className="lg:text-[1.7vw] lg:mb-[3%]
									text-center font-light mb-[2%] text-[4vw] mb-[12%]">Juin 2018 - Décembre 2018</p>
				<h2 className="lg:text-[1.5vw] lg:mb-[1%]
									text-center font-bold underline underline-offset-8 text-[4vw] mb-[5%]">Projets : </h2>
				<p className="lg:text-[1.5vw] lg:w-[65%]
									font-light text-left text-[4vw] text-center">
					- Apprentissage du <span className={highLight}>Python</span> <br/>
					<br/>
					- Conversion d'un package Bash en <span className={highLight}>Python</span> <br />
					<br />
					- Création de packages <span className={highLight}>Python</span> <br />
					<br />
					- Réalisation de <span className={highLight}>test unitaire</span> en utilisant <span className={highLight}>Pytest</span> <br />
					<br />
					- <span className={highLight}>Refactorisation</span> de code <br />
				</p>
			</div>
		</div>
	);
}

export default Experiences;