import React, { useRef } from "react";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";


function HeadDisplay({path, position, scale, rotation, rotationSpeed}) {
	const { nodes, materials } = useGLTF(path);

	const group = useRef();

	useFrame(() => {
		group.current.rotation.z += rotationSpeed
	})

	return (
		<group ref={group}>
			<ambientLight intensity={0.3} ></ambientLight>
			<directionalLight intensity={0.5} color="white" position={[0, -1, 0]} castShadow></directionalLight>
			<directionalLight intensity={0.5} color="white" position={[0, 1, 0]} castShadow></directionalLight>
			<directionalLight intensity={0.5} color="white" position={[-1, 0, 0]} castShadow></directionalLight>
			<directionalLight intensity={0.5} color="white" position={[1, 0, 0]} castShadow></directionalLight>
			<directionalLight intensity={1} color="white" position={[0, 0, 2]} castShadow></directionalLight>
			<mesh
				receiveShadow
				castShadow
				geometry={nodes.Layer1.geometry}
				material={materials["wsh"]}
				rotation={rotation}
				position={position}
				scale={scale}
			/>
		</group>
	);
}

useGLTF.preload("/Untitled.gltf");
useGLTF.preload("/chockbar.gltf");
useGLTF.preload("/herobrine.gltf");
useGLTF.preload("/squint.gltf");
useGLTF.preload("/c#logo.gltf");

export default HeadDisplay;