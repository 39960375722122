function Hobbies({highLight}) {
	return (
		<div className="w-full h-auto flex flex-col flex-wrap text-orange-100 items-center">
			<div className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">Jeux vidéo : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Que ça soit y jouer depuis mon enfance, ou les <span className={highLight}>créer</span> depuis quelques années, les jeux vidéo sont pour moi ma plus grande <span className={highLight}>passion</span>, et un medium incroyable pour raconter tout type d'histoire, et pour exprimer sa <span className={highLight}>créativité</span>
					</p>
				</div>
			</div>
			<div className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">Musique : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Plus particulièrement le <span className={highLight}>rap US</span>, que ça soit du rap de la East Coast des années 90 comme <span className={highLight}>A Tribe Called Quest</span> ou alors du rap alternatif moderne comme <span className={highLight}>JPEGMAFIA</span>
					</p>
				</div>
			</div>
			<div className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">Gunpla : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						J'ai découvert ça à travers <span className={highLight}>Keroro</span>, un anime qui passait sur Teletoon dans mon enfance, et depuis mon amour pour les gros robots japonais n'a pas bougé, et pouvoir les construire sous forme de <span className={highLight}>maquette</span> c'est trop fun
					</p>
				</div>
			</div>
			<div className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">Tatouage : </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Une façon de se <span className={highLight}>réapproprier</span> son corps que je trouve magnifique, une façon <span className={highLight}>d'exprimer</span> qui on est unique et une forme <span className={highLight}>d'art</span> incroyable
					</p>
				</div>
			</div>
		</div>
	)
}

export default Hobbies;