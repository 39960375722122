import "./index.css"
import MainWindow from "./components/MainWindow";
import Header from "./components/Header";
import { useRef } from "react";

function App() {
	const ref = useRef();

	const headList = [
		"/gltf/Untitled.gltf",
		"/gltf/Untitled.gltf",
		"/gltf/Untitled.gltf",
		"/gltf/Untitled.gltf",
		"/gltf/Untitled.gltf",
		"/gltf/Untitled.gltf",
		"/gltf/chockbar.gltf",
		"/gltf/chockbar.gltf",
		"/gltf/chockbar.gltf",
		"/gltf/chockbar.gltf",
		"/gltf/chockbar.gltf",
		"/gltf/chockbar.gltf",
		"/gltf/squint.gltf",
		"/gltf/squint.gltf",
		"/gltf/squint.gltf",
		"/gltf/squint.gltf",
		"/gltf/squint.gltf",
		"/gltf/squint.gltf",
		"/gltf/herobrine.gltf",
	];

	const path = headList[Math.floor(Math.random() * headList.length)];

	return (
		<div className="h-dvh w-screen flex flew-row flex-wrap overflow-y-scroll snap-y" ref={ref}>
			<Header path={path}/>
			<MainWindow path={path} windowRef={ref}/>
		</div>
	)
}

export default App;