import NavigationContext from "../context/navigation";
import { useContext } from "react";

function Footer () {
	const { navigate } = useContext(NavigationContext);

	const handleClickLegalMentions = (event) => {
		if (event.metaKey || event.ctrlKey)
			return;
		event.preventDefault();

		navigate("/mentions-legales");
	};

	return (
		<div className="text-orange-100 w-[90%] lg:text-[0.8vw] text-[3vw] text-center py-[1%] cursor-pointer transition-transform duration-300 hover:scale-110" onClick={handleClickLegalMentions}>
			Mentions Légales
		</div>
	)
}

export default Footer;