import Navbar from "./Navbar";
import ContentWindow from "./ContentWindow";
import Footer from "./Footer";
import { useRef } from "react";

function MainWindow({path, windowRef}) {
	const ref = useRef(null);

	const handleClick = () => {
		windowRef.current.scrollTo({ top: ref.current.offsetTop })
	}
	
	return (
		<div className="lg:snap-center
							w-full h-auto min-h-dvh flex flex-col items-center z-10 bg-gradient-to-t from-blue-950 to-slate-950" ref={ref}>
			<Navbar path={path} handleClick={handleClick}/>
			<ContentWindow />
			<Footer />
		</div>
	)
}

export default MainWindow;