import { useContext } from "react";
import NavigationContext from "../context/navigation";
import classNames from "classnames";

function Link({ to, children, className, activeClassName, resetPos }) {
	const { navigate, currentPath } = useContext(NavigationContext);
	
	const classes = classNames(
		"block group-hover:max-w-full transition-all duration-400 lg:h-1 h-0.5 bg-orange-100",
		currentPath === to ? activeClassName : "max-w-0"
	);

	const handleClick = (event) => {
		if (event.metaKey || event.ctrlKey)
			return ;
		event.preventDefault();

		navigate(to);
		resetPos();
	};

	return (
		<a className={className} href={to} onClick={handleClick}>
			{children}
			<span className={classes}></span>
		</a>
	)
}

export default Link;