import NaerisLogo from "../images/Logo_AE.png"
import Sparkles from "../images/sparkles.png"
import Tetrimuros from "../images/tetrimurosLogo.png"
import Pokeball from "../images/pokeball.png"

function Portfolio ({highlight}) {
	return (
		<div className="w-full h-fit min-h-screen flex flex-col text-orange-100 items-center">
			<a className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100" href="https://naeris.fr">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">NAERIS TATTOO: </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Site portfolio pour le tatoueur <span className={highlight}>Naeris</span>. <br/>
						Servant aussi de point de contact et de <span className={highlight}>flashbook interactif</span>. <br/>
						Utilise <span className={highlight}>prismic.io</span> en tant que base de données. <br/>
						Utilise <span className={highlight}>ReactJS</span> et <span className={highlight}>TailwindCSS</span> pour la partie front.
					</p>
				</div>
				<img src={NaerisLogo} alt="Logo Naeris" className="lg:size-[8vw] size-[25vw] transition-transform duration-300 hover:scale-110" />
			</a>
			<a className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100" href="https://sketch-pokemon.vercel.app/">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">SKETCHES BY ROSE: </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Projet personnel marchant comme un sketchbook. <br/>
						J'ai toujours voulu apprendre à dessiner donc le but est que je dessine tous les Pokemon voir si je progresse. <br/>
						Utilise <span className={highlight}>prismic.io</span> en tant que base de données. <br />
						Utilise <span className={highlight}>ReactJS</span> et <span className={highlight}>TailwindCSS</span> pour la partie front.
					</p>
				</div>
				<img src={Pokeball} alt="Logo sketches by rose" className="lg:size-[12vw] size-[30vw] transition-transform duration-300 hover:scale-110" />
			</a>
			<a className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100" href="https://shiny-tracker-khaki.vercel.app/">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">SHINY TRACKER: </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Site permettant de garder compte du nombre de rencontre durant une chasse de pokémon shiny. <br />
						Utilise <span className={highlight}>ReactJS</span> et <span className={highlight}>TailwindCSS</span> pour la partie front. <br/>
						Appelle l'API <span className={highlight}>PokéAPI</span> pour récupérer les informations sur les pokémons.
					</p>
				</div>
				<img src={Sparkles} alt="Logo Shiny Tracker" className="lg:size-[8vw] size-[25vw] transition-transform duration-300 hover:scale-110" />
			</a>
			<a className="lg:w-[60%] w-[95%] h-fit flex flex-row items-center justify-between lg:border-b-[4px] border-b-[2px] border-orange-100" href="https://rowose.itch.io/tetrimuros">
				<div className="w-fit mb-[5%] mt-[5%]">
					<h1 className="text-left underline underline-offset-8 font-bold lg:text-[1.7vw] lg:mt-[0%] mt-[5%] lg:mb-[3%] mb-[8%] text-[5vw]">TETRIMUROS: </h1>
					<p className="font-light text-left lg:text-[1.5vw] text-[4vw]">
						Tetrimuros est un jeu simple où il faut faire tourner une pièce de Tetris pour qu'elle passe dans les murs vous approchant. <br />
						Mais si vous ne faites pas passer l'exact bonne forme, cela ne vous donnera pas de point. <br />
						Fait sur le moteur <span className={highlight}>Unity</span>.
					</p>
				</div>
				<img src={Tetrimuros} alt="Logo Tetrimuros" className="lg:size-[8vw] size-[25vw] transition-transform duration-300 hover:scale-110" />
			</a>
		</div>
	)
}

export default Portfolio;