import { Canvas } from "@react-three/fiber";
import HeadDisplay from "./HeadDisplay";
import Link from "./Link";


function Navbar({path, handleClick}) {
	const links = [
		{ label: 'Compétences', path: '/' },
		{ label: 'Expériences', path: '/experiences' },
		{ label: 'Formation', path: '/formation' },
		{ label: 'Hobbies', path: '/hobbies' },
		{ label: 'Portfolio', path: '/portfolio' },
	];

	const renderedLinks = links.map((link) => {
		return <Link className="lg:text-[1.8vw] lg:decoration-4 lg:underline-offset-4
									decoration-2 underline-offset-2 text-[3vw] text-orange-100 font-bold group
									transition duration-300"
									activeClassName="max-w-full"
									to={link.path}
									key={link.label}
									resetPos={handleClick}
									>
					{link.label}
				</Link>
	})

	return (
		<div className="lg:h-[5vw] lg:border-b-[4px]
							h-[14vw] w-full border-b-[2px] border-orange-100 sticky top-[0%] z-20 bg-slate-950">
			<div className="w-[90%] h-full ml-[5%] flex flex-row flex-wrap">
				<div className="lg:w-[15%] lg:h-[100%]
									w-[0%] h-[0%] mr-[5%] transition-transform duration-300 hover:scale-110">
					<Canvas>
						<HeadDisplay path={path} position={[0, -1, 0.1]} scale={0.25} rotation={[-45.3, -0.10, 0.4]} rotationSpeed={0}></HeadDisplay>
					</Canvas>
				</div>
				<div className="lg:w-[80%] lg:justify-between lg:ml-[-3%] lg:mb-[0%]
									flex w-[100%] mb-[3%] text-white items-center flex-row flex-wrap justify-evenly">
					{renderedLinks}
				</div>
			</div>
		</div>
	)
}

export default Navbar;