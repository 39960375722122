import Route from "./Route"
import Formation from "./Formation";
import Skills from "./Skills";
import Experiences from "./Experiences";
import Hobbies from "./Hobbies";
import Portfolio from "./Portfolio";
import LegalMentions from "./LegalMentions";

function ContentWindow() {
	const highlightedWords = "font-bold";

	return (
		<div className="text-white w-[85%] h-fit">
			<Route path="/">
				<Skills />	
			</Route>
			<Route path="/experiences">
				<Experiences highLight={highlightedWords}/>
			</Route>
			<Route path="/formation">
				<Formation highLight={highlightedWords} />
			</Route>
			<Route path="/hobbies">
				<Hobbies highLight={highlightedWords}/>
			</Route>
			<Route path="/portfolio">
				<Portfolio highlight={highlightedWords}/>
			</Route>
			<Route path="/mentions-legales">
				<LegalMentions />
			</Route>
		</div>
	)
}

export default ContentWindow;